<template>
  <div class="container-page big">
    <div class="filter__block mb-4">
      <ButtonStock title="Карта проектов" @click="onProjectMapClick" />
    </div>

    <SectionStatistics
      class="distribution-of-projects"
      title="Распределение проектов по отраслям"
      type="distribution-of-projects"
      :optionCheckboxCountries="countries"
      :optionCheckboxStatus="statuses"
      :filterMpkData="byIndustry.filter"
      @changeData="beforeChangeFilter($event, 0, 'byIndustry')"
      @clickMore="onClickedBar0"
    >
      <Loader v-if="loading.byIndustry"></Loader>
      <div v-else-if="byIndustry.projects?.length > 0" class="monitoring-content__chart">
        <ColumnChartHorizontal
          :key="loading.byIndustry"
          :bar-percentage="1"
          index-axis="y"
          labelColor="#fff"
          :labels="byIndustry.projects.map((item) => makeChartLabel(capitalize(item.name), 50))"
          :chart-data="byIndustry.projects.map((item) => item.data)"
          @clickedBar="onClickedBar0"
        />
      </div>
      <div v-else>Нет данных...</div>
    </SectionStatistics>

    <SectionStatistics
      class="distribution-of-projects"
      title="Распределение проектов по целям"
      type="distribution-of-projects"
      :optionCheckboxCountries="countries"
      :optionCheckboxStatus="statuses"
      :filterMpkData="byPurpose.filter"
      @changeData="beforeChangeFilter($event, 1, 'byPurpose')"
      @clickMore="onClickedBar1"
    >
      <Loader v-if="loading.byPurpose"></Loader>
      <div v-else-if="byPurpose.projects?.length > 0" class="monitoring-content__chart">
        <div class="chart-inner overflow-auto">
          <ColumnChartHorizontal
            :key="loading.byPurpose"
            :bar-percentage="1"
            index-axis="y"
            labelColor="#fff"
            :labels="byPurpose.projects.map((item) => makeChartLabel(capitalize(item.name), 50))"
            :chart-data="byPurpose.projects.map((item) => item.data)"
            @clickedBar="onClickedBar1"
          />
        </div>
      </div>
      <div v-else>Нет данных...</div>
    </SectionStatistics>

    <SectionStatistics
      class="distribution-of-projects"
      title="Распределение проектов по странам"
      type="distribution-of-projects"
      :optionCheckboxCountries="countries"
      :optionCheckboxStatus="statuses"
      :filterMpkData="byCountry.filter"
      @changeData="beforeChangeFilter($event, 2, 'byPurbyCountrypose')"
      @clickMore="onClickedBar2"
    >
      <!--
        ререндерится графики при получении ответов по апишкам для каждого из графиков
        чтобы этого избежать пришлось добавить такое условие
        + закоментировать внутри каждого из объектов byCountry и тд
        внутр. св-во projects: [],
        ...
      -->
      <Loader v-if="loading.byCountry"></Loader>
      <div v-else-if="byCountry.projects?.length > 0" class="monitoring-content__chart">
        <div class="chart-inner overflow-auto">
          <ColumnChartHorizontal
            :key="loading.byCountry"
            :bar-percentage="1"
            index-axis="y"
            labelColor="#fff"
            :labels="byCountry.projects.map((item) => makeChartLabel(capitalize(item.name.toLowerCase()), 50))"
            :chart-data="byCountry.projects.map((item) => item.data)"
            @clickedBar="onClickedBar2"
          />
        </div>
      </div>
      <div v-else>Нет данных...</div>
    </SectionStatistics>

    <SectionStatistics
      class="distribution-of-projects"
      title="Распределение проектов по МПК"
      type="distribution-of-projects"
      :optionCheckboxCountries="countries"
      :optionCheckboxStatus="statuses"
      :filterMpkData="byMpk.filter"
      @changeData="beforeChangeFilter($event, 3, 'byMpk')"
      @clickMore="onClickedBar3"
    >
      <Loader v-if="loading.byMpk"></Loader>
      <div v-else-if="byMpk.projects?.length > 0" class="monitoring-content__chart">
        <div class="chart-inner overflow-auto">
          <ColumnChartHorizontal
            :key="loading.byMpk"
            :bar-percentage="1"
            index-axis="y"
            labelColor="#fff"
            :labels="byMpk.projects.map((item) => truncate(capitalize(item.name.toLowerCase()), 50))"
            :chart-data="byMpk.projects.map((item) => item.data)"
            @clickedBar="onClickedBar3"
          />
        </div>
      </div>
      <div v-else>Данных нет</div>
    </SectionStatistics>

    <SectionStatistics
      title="Динамика изменения портфеля проектов МПК"
      type="portfolio-change-dynamics"
      :optionCheckboxCountries="countries"
      :optionCheckboxStatus="statuses"
      @changeData="debounceDynamicsChangesProjectPortfolio"
    >
      <Loader v-if="loading.byPCD"></Loader>
      <LineGraph v-else v-bind="dataChangesProjectPortfolio" />
    </SectionStatistics>

    <SectionStatistics
      title="Динамика количества, статусов, включения и исключения проектов МПК"
      type="dynamics-quantity-status-inclusion-exclusion"
      :optionCountries="optionCountries"
      :optionProjectsMPKPortfolio="optionProjectsMPKPortfolio"
      :optionProjectMPK="optionProjectMPK"
      @changeData="debounceComplexanAlyticDiagram"
      idPeriodFrom="period-from-exclusion"
      idPeriodTo="period-to-exclusion"
    >
      <Loader v-if="loading.byDQSIE"></Loader>
      <template v-else>
        <LineGraph v-bind="dataDynamicsProjects" :title="getCountryName" />
        <TableProject
          :tableInfo="tableInfoProjects"
          @onPageChange="onPageChangeTableProjects"
          @onPageMore="onPageMoreTableProjects"
        />
      </template>
    </SectionStatistics>

    <SectionStatistics
      title="Количество созданных рабочих мест"
      type="number-jobs-created"
      :optionCountries="optionCountries"
      @changeData="debounceJobsCreated"
      idPeriodFrom="period-from-jobs"
      idPeriodTo="period-to-jobs"
    >
      <Loader v-if="loading.byNJC"></Loader>
      <VerticalBarChart
        v-else
        v-bind="jobsCreated"
        class="verticalBarChart--project-statistics"
        title="Количество рабочих мест"
        pointSize="8"
        :noData="noDataJobsCreated"
      />
    </SectionStatistics>

    <SectionStatistics
      title="Доля «зелёных» проектов в портфеле МПК"
      type="share-green-projects"
      :optionCountries="optionCountries"
      :optionProjectsMPKPortfolio="optionProjectsMPKPortfolio"
      @changeData="onLoadProjectsMPKPortfolio"
    >
      <Loader v-if="loading.bySGP"></Loader>
      <ChartPieProject v-else v-bind="donut" />
    </SectionStatistics>
  </div>
</template>

<script>
  // Additional
  import { debounce } from 'lodash';

  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import text from '@/common/mixins/text';
  import API_COUNTRY from '@/common/utils';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import Loader from '@/components/Loader';

  import API_COMMISSION from '@/modules/mpk/api/commission';
  import VerticalBarChart from '@/modules/mpkEffectiveRating/components/VerticalBarChart.vue';
  import API_NSI from '@/modules/nsi/api/nsi';

  import ColumnChartHorizontal from '../../../components/column-chart-horizontal';
  import API from '../api/index';
  import ChartPieProject from '../components/ChartPieProject.vue';
  import LineGraph from '../components/LineGraph.vue';
  import SectionStatistics from '../components/SectionStatistics.vue';
  import TableProject from '../components/TableProject.vue';
  import constants from '../constants.js';

  export default {
    name: 'ProjectsStatistics',
    components: {
      ColumnChartHorizontal,
      ButtonStock,
      Loader,
      VerticalBarChart,
      ChartPieProject,
      LineGraph,
      SectionStatistics,
      TableProject,
    },
    mixins: [security, text],
    data() {
      return {
        loading: {
          byIndustry: false,
          byPurpose: false,
          byCountry: false,
          byMpk: false,
          byPCD: false,
          byDQSIE: false,
          byNJC: false,
          bySGP: false,
        },
        request: {
          pageNumber: 1,
          pageSize: 0,
        },
        requestMpk: {
          pageNumber: 1,
          pageSize: 0,
          shortFormat: true,
          HasParent: false,
        },
        countries: [],
        purposes: [],
        industries: [],
        mpkProjects: [],
        statuses: [],
        projects: [],
        byIndustry: {
          filter: {
            countryIds: [],
            statusIds: [],
          },
        },
        byPurpose: {
          filter: {
            countryIds: [],
            statusIds: [],
          },
        },
        byCountry: {
          filter: {
            industryIds: [],
            statusIds: [],
          },
        },
        byMpk: {
          filter: {
            countryIds: [],
            statusIds: [],
          },
        },
        // динамика изменения портфеля проектов мпк
        dataChangesProjectPortfolio: {
          countArray: [],
          labelsArray: [],
          title: 'Количество проектов',
          height: 580,
        },

        // динамика количества, статусов, включения и исключения мпк
        dataDynamicsProjects: {
          countArray: [],
          labelsArray: [],
          height: 580,
        },
        defaultComplexanAlyticDiagram: {
          CountryId: constants.DEFAULT_COUNTRY,
          MpkEventMeetingId: 0,
          DynamicMode: constants.DEFAULT_DYNAMIC,
        },
        paramsGraphComplexanAlyticDiagram: {},
        projectsCurrentPeriod: {
          pageNumber: 1,
          pageSize: 10,
        },
        tableInfoProjects: {
          pageNumber: 1,
          pageSize: 10,
          items: [],
          itemsTotal: 0,
          pagesTotal: 1,
        },

        // кол-во созданых раб. мест
        jobsCreated: {
          labels: [],
          datasets: [],
        },
        noDataJobsCreated: false,

        // доля зел. проектов в протфеле мпк
        donut: {
          labelsArray: constants.DONUT_PROJECTS_MPK_PORTFOLIO_NAME,
          countArray: [],
          color: constants.DONUT_PROJECTS_MPK_PORTFOLIO_COLOR,
          info: constants.DONUT_PROJECTS_MPK_PORTFOLIO_INFO,
        },

        optionCountries: [],
        optionProjectsMPKPortfolio: [],
        optionProjectMPK: [],
        choiceCountryId: constants.DEFAULT_COUNTRY,
      };
    },
    computed: {
      currentFilter() {
        let filters = {
          byIndustry: this.byIndustry.filter,
          byPurpose: this.byPurpose.filter,
          byCountry: this.byCountry.filter,
          byMpk: this.byMpk.filter,
        };
        return filters;
      },

      getCountryName() {
        const index = this.countries.findIndex((i) => +i.id === +this.choiceCountryId);
        if (index !== -1) {
          return this.countries[index].text;
        }
        return '';
      },
    },
    created() {
      this.checkPermission(Constants.Permissions.MpkProjectsAccess).then((res) => {
        if (!res.data) {
          Constants.alert.fire('Нет доступа', 'У Вас нет доступа к просмотру статистики проектов.', 'error');
          this.$router.push('/');
        } else {
          this.loadData();

          API.getMpkEventMeetingList()
            .then((response) => {
              if (response.data.length) {
                this.optionProjectsMPKPortfolio = response.data.map((i) => ({
                  value: i.id,
                  label: i.text,
                }));
              }
            })
            .catch((err) => {
              console.error('error getMpkEventMeetingList', err);
            });

          API.getDynamicModelist()
            .then((response) => {
              if (response.data.length) {
                this.optionProjectMPK = response.data.map((i) => ({
                  value: i.id,
                  label: i.text,
                }));
              }
            })
            .catch((err) => {
              console.error('error getDynamicModelist', err);
            });

          this.onLoadDataDynamicsChangesProjectPortfolio();

          this.onLoadComplexanAlyticDiagram();

          this.onLoadJobsCreated();

          this.onLoadProjectsMPKPortfolio();
        }
      });
    },
    methods: {
      capitalize(str) {
        return str[0].toUpperCase() + str.slice(1);
      },

      loadPage(byIndustry, byPurpose, byCountry, byMpk) {
        if (byIndustry) {
          this.loadProjects(this.byIndustry.filter, 'industryId', this.byIndustry, this.industries);
        }
        if (byPurpose) {
          this.loadProjects(this.byPurpose.filter, 'purposeId', this.byPurpose, this.purposes);
        }
        if (byCountry) {
          this.loadProjects(this.byCountry.filter, 'countryId', this.byCountry, this.countries);
        }
        if (byMpk) {
          this.loadProjects(this.byMpk.filter, 'mpkComissionId', this.byMpk, this.mpkProjects);
        }
      },
      loadProjects(filter, keyField, obj, dict) {
        if (keyField == 'countryId') {
          this.loading.byCountry = true;
        } else if (keyField == 'industryId') {
          this.loading.byIndustry = true;
        } else if (keyField == 'purposeId') {
          this.loading.byPurpose = true;
        } else if (keyField == 'mpkComissionId') {
          this.loading.byMpk = true;
        }
        var params = Object.assign({}, filter, this.request);

        API.search(params).then((response) => {
          this.projects = [];
          this.$nextTick(() => {
            var resReduced = response.data.items.reduce((grouped, item) => {
              const key = item[keyField];
              if (key) {
                if (!grouped[key]) {
                  grouped[key] = [];
                }
                // Add object to list for given key's value
                grouped[key].push(item);
              }
              return grouped;
            }, {});
            var resArr = [];
            for (const [key, value] of Object.entries(resReduced)) {
              let dictItemname = dict.find((d) => d.id == key)?.text || '';
              resArr.push({ name: dictItemname, data: value.length });
            }
            obj.projects = resArr.sort(function (a, b) {
              return b.data - a.data;
            });
          });
          if (keyField == 'countryId') {
            this.loading.byCountry = false;
          } else if (keyField == 'industryId') {
            this.loading.byIndustry = false;
          } else if (keyField == 'purposeId') {
            this.loading.byPurpose = false;
          } else if (keyField == 'mpkComissionId') {
            this.loading.byMpk = false;
          }
        });
      },
      loadData() {
        //страны
        API_COUNTRY.searchNsi('countryList').then((response) => {
          if (response.data && response.data.length) {
            response.data.forEach((i) => this.countries.push(i));
            response.data.forEach((i) =>
              this.optionCountries.push({
                value: i.id,
                label: i.text,
                disabled: false,
              }),
            );
          }
          this.loadProjects(this.byCountry.filter, 'countryId', this.byCountry, this.countries);
        });
        Utils.loadSelectOptions('mpkProjectStatusList', this.statuses);
        //отрасли
        API_NSI.search('NsiIndustry', this.request).then((response) => {
          if (response.data) {
            this.industries = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
          this.loadProjects(this.byIndustry.filter, 'industryId', this.byIndustry, this.industries);
        });
        //цели
        API_NSI.search('MpkProjectPurpose', this.request).then((response) => {
          if (response.data) {
            this.purposes = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
          this.loadProjects(this.byPurpose.filter, 'purposeId', this.byPurpose, this.purposes);
        });
        // мпк
        API_COMMISSION.search(this.requestMpk).then((response) => {
          if (response.data) {
            this.mpkProjects = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
          this.loadProjects(this.byMpk.filter, 'mpkComissionId', this.byMpk, this.mpkProjects);
        });
      },
      changeFilter(mode) {
        this.saveFilter(this.currentFilter);
        this.loadPage(mode === 0, mode === 1, mode === 2, mode === 3);
      },
      onClickedBar0(index) {
        let industryId;
        if (index >= 0) {
          const industry = this.byIndustry.projects[index].name;
          industryId = this.industries.find((i) => i.text == industry).id;
        }
        this.$router.push({
          name: 'Projects',
          params: {
            countryId: this.byIndustry.filter.countryIds,
            statusId: this.byIndustry.filter.statusIds,
            industryId: industryId ? [industryId] : null,
          },
        });
      },
      onClickedBar1(index) {
        let purposeId;
        if (index >= 0) {
          const purpose = this.byPurpose.projects[index].name;
          purposeId = this.purposes.find((i) => i.text == purpose).id;
        }
        this.$router.push({
          name: 'Projects',
          params: {
            countryId: this.byPurpose.filter.countryIds,
            statusId: this.byPurpose.filter.statusIds,
            purposeId: purposeId ? [purposeId] : null,
          },
        });
      },
      onClickedBar2(index) {
        let countryId;
        if (index >= 0) {
          const country = this.byCountry.projects[index].name;
          countryId = this.countries.find((i) => i.text == country).id;
        }
        this.$router.push({
          name: 'Projects',
          params: {
            industryId: this.byCountry.filter.industryIds,
            statusId: Object.assign([], this.byCountry.filter.statusIds),
            countryId: countryId ? [countryId] : null,
          },
        });
      },
      onClickedBar3(index) {
        let mpkComissionId;
        if (index >= 0) {
          const mpkComission = this.byMpk.projects[index].name;
          mpkComissionId = this.mpkProjects.find((i) => i.text == mpkComission).id;
        }
        this.$router.push({
          name: 'Projects',
          params: {
            mpkComissionId: mpkComissionId,
            statusId: this.byMpk.filter.statusIds,
            countryId: this.byMpk.filter.countryIds,
          },
        });
      },
      onProjectMapClick() {
        this.$router.push({ name: 'ProjectsMap' });
      },

      formattingParamsChangesProjectPortfolio(params) {
        if (params) {
          const { CountryIds, StatusIds } = params;
          let format = '';
          if (CountryIds.length) {
            CountryIds.forEach((i) => {
              if (!format) {
                format = `CountryIds=${i}`;
              } else {
                format += `&CountryIds=${i}`;
              }
            });
          }
          if (StatusIds.length) {
            StatusIds.forEach((i) => {
              if (!format) {
                format = `StatusIds=${i}`;
              } else {
                format += `&StatusIds=${i}`;
              }
            });
          }
          return format;
        }
      },

      onLoadDataDynamicsChangesProjectPortfolio(params) {
        this.loading.byPCD = true;
        API.getDynamicsChangesProjectPortfolio(this.formattingParamsChangesProjectPortfolio(params))
          .then((response) => {
            const data = response.data;
            if (data !== null) {
              const countArray = [];
              const labelsArray = [];
              const color = constants.COLORS;
              let colorCounter = 0;

              for (const [key, value] of Object.entries(data)) {
                const dataList = [];
                for (const [keyJ, valueJ] of Object.entries(value)) {
                  dataList.push(valueJ);
                  labelsArray.push(keyJ);
                }
                countArray.push({
                  name: key,
                  data: dataList,
                  color: color[colorCounter],
                });
                ++colorCounter;
              }

              this.dataChangesProjectPortfolio.countArray = countArray;
              this.dataChangesProjectPortfolio.labelsArray = labelsArray;
            } else {
              this.dataChangesProjectPortfolio.countArray = [];
              this.dataChangesProjectPortfolio.labelsArray = [];
            }
          })
          .catch((err) => {
            console.error('error onLoadDataDynamicsChangesProjectPortfolio', err);
          })
          .finally(() => {
            this.loading.byPCD = false;
          });
      },

      debounceDynamicsChangesProjectPortfolio: debounce(function (data) {
        this.onLoadDataDynamicsChangesProjectPortfolio(data);
      }, 500),

      getLabelGraph(key) {
        if (key === 'currentPeriod') {
          return constants.CURRENT_PERIOD;
        }
        if (key === 'previousPeriod') {
          return constants.PREVIOUS_PERIOD;
        }

        return key;
      },

      onLoadProjectsForStatistics(params) {
        API.getSearchProjectsForStatistics({
          ...params,
          ...this.projectsCurrentPeriod,
        })
          .then((response) => {
            if (response.data !== null) {
              this.tableInfoProjects = response.data;
            } else {
              this.tableInfoProjects = {
                pageNumber: 1,
                pageSize: 10,
                items: [],
                itemsTotal: 0,
                pagesTotal: 1,
              };
            }
          })
          .catch((err) => {
            console.error('error onLoadProjectsForStatistics', err);
          });
      },

      onPageChangeTableProjects(count) {
        this.projectsCurrentPeriod.pageNumber = count;
        this.onLoadProjectsForStatistics(this.paramsGraphComplexanAlyticDiagram);
      },

      onPageMoreTableProjects(count) {
        this.projectsCurrentPeriod.pageNumber = count;
        API.getSearchProjectsForStatistics({
          ...this.paramsGraphComplexanAlyticDiagram,
          ...this.projectsCurrentPeriod,
        })
          .then((response) => {
            this.tableInfoProjects.items = this.tableInfoProjects.items.concat(response.data.items);
          })
          .catch((err) => {
            console.error('error onLoadProjectsForStatistics', err);
          });
      },

      onLoadComplexanAlyticDiagram(params = this.defaultComplexanAlyticDiagram) {
        this.loading.byDQSIE = true;
        API.getComplexanAlyticDiagram(params)
          .then((response) => {
            this.paramsGraphComplexanAlyticDiagram = params;
            if (params.CountryId) {
              this.choiceCountryId = params.CountryId;
            }
            const data = response.data;
            if (data !== null) {
              const countArray = [];
              const labelsArray = [];
              const color = constants.COLORS;
              let colorCounter = 0;

              for (const [key, value] of Object.entries(data)) {
                const dataList = [];
                for (const [keyJ, valueJ] of Object.entries(value)) {
                  dataList.push(valueJ);
                  labelsArray.push(keyJ);
                }
                countArray.push({
                  name: this.getLabelGraph(key),
                  data: dataList,
                  color: color[colorCounter],
                });
                ++colorCounter;
              }

              this.dataDynamicsProjects.countArray = countArray;
              this.dataDynamicsProjects.labelsArray = labelsArray;
            } else {
              this.dataDynamicsProjects.countArray = [];
              this.dataDynamicsProjects.labelsArray = [];
            }
          })
          .catch((err) => {
            console.error('error onLoadComplexanAlyticDiagram', err);
          })
          .finally(() => {
            this.loading.byDQSIE = false;
          });

        this.onLoadProjectsForStatistics(params);
      },

      debounceComplexanAlyticDiagram: debounce(function (data) {
        this.onLoadComplexanAlyticDiagram(data);
      }, 1000),

      onLoadJobsCreated(params) {
        this.loading.byNJC = true;
        API.getNumberJobsCreated(params)
          .then((response) => {
            const data = response.data;
            const labels = Object.keys(data);
            const datasetTemporary = {
              label: constants.NUMBER_OF_TEMPORARY_JOBS,
              data: [],
              backgroundColor: '#01A39D',
            };
            const datasetsPermanent = {
              label: constants.NUMBER_OF_PERMANENT_JOBS,
              data: [],
              backgroundColor: '#0078C8',
            };

            if (Object.keys(data).length) {
              Object.values(data).forEach((i) => {
                datasetTemporary.data.push({ x: +i.transient });
                datasetsPermanent.data.push({ x: +i.constant });
              });
            }

            this.jobsCreated.labels = labels;
            this.jobsCreated.datasets = [datasetTemporary, datasetsPermanent];

            if (!labels.length) {
              this.noDataJobsCreated = true;
            } else {
              this.noDataJobsCreated = false;
            }
          })
          .catch((err) => {
            console.error('error onLoadJobsCreated', err);
          })
          .finally(() => {
            this.loading.byNJC = false;
          });
      },

      debounceJobsCreated: debounce(function (data) {
        this.onLoadJobsCreated(data);
      }, 1000),

      onLoadProjectsMPKPortfolio(params) {
        this.loading.bySGP = true;
        API.getProjectsMPKPortfolio(params)
          .then((response) => {
            const { doneProjects = 0, allProjects = 0 } = response.data;
            if (!doneProjects && !allProjects) {
              this.donut.countArray = [];
            } else {
              this.donut.countArray = [doneProjects, allProjects - doneProjects];
            }
            this.donut.info.forEach((i) => {
              if (i.id === 'implemented-projects') {
                i.count = doneProjects;
              }
              if (i.id === 'total-projects') {
                (i.count = allProjects), (i.color = '#231F20');
              }
            });
          })
          .catch((err) => {
            console.error('error onLoadProjectsMPKPortfolio', err);
          })
          .finally(() => {
            this.loading.bySGP = false;
          });
      },

      beforeChangeFilter(data, index, type) {
        let { CountryIds, StatusIds } = data;
        this[type].filter.countryIds = CountryIds;
        this[type].filter.statusIds = StatusIds;
        this.changeFilter(index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-inner {
    max-height: 1200px;
  }
  .monitoring {
    &-container {
      margin-bottom: 70px;

      .title {
        font-weight: 500;
        margin-bottom: 24px;
      }
    }
    &-content {
      &__header {
        .filter {
          display: flex;
          margin-bottom: 32px;
          width: 100%;
          &__block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 25%;
            font-family: 'Fira Sans', sans-serif;
            background-color: transparent;
            padding: 0;
            &:not(:last-of-type) {
              margin-right: 18px;
            }
            .input-container {
              width: 100%;
            }
          }
        }
      }
    }
  }
  // Media
  @media (max-width: 1024px) {
    .monitoring-content__header .filter {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 768px) {
    .monitoring-content__header .filter__block:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
</style>
